$dark: #283739;
$light: #e0e0e0;
$mainGreen: #a2c11c;
$mainBlue: #2c5d63;
$navBarHeight: 85px;
$headerHeight: 70px;
$searchBarHeight: 75px;

//import bootstrap
@import "~bootstrap/scss/bootstrap";

//override bootstrap presets
$theme-colors: (
  "primary": $mainGreen,
  "info": $mainBlue,
);

$accordion-button-active-bg: white;
$accordion-button-active-color: black;
$accordion-button-focus-border-color: $mainBlue;
$accordion-button-focus-box-shadow: 0px 0px 5px 1px rgb(44, 93, 99, 0.5);

$min-contrast-ratio: 2;

//reimport updated bootstrap styles
@import "~bootstrap/scss/bootstrap";

//
//start of custom styling
//

body {
  color: $dark;
}

.header {
  height: $headerHeight;
  background-color: $mainBlue;
  display: flex;
  justify-content: space-between;
  align-items: center;

  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 10000;

  img {
    height: 150px;
    align-self: center;
    margin-left: 20px;
    cursor: pointer;
  }

  .hideNav {
    display: none;
  }
}

nav {
  height: $navBarHeight;
  img {
    height: 35px;
    cursor: pointer;
  }
  p {
    margin: 0;
    font-size: 0.7rem;
  }

  .nav {
    background-color: $light;
    padding: 10px;

    .container {
      // max-width: 450px;
      margin: 0 auto;
    }
  }
}

.required:after {
  content: "*";
}

.welcomePage {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: $mainBlue;
  align-content: center;

  .animate {
    opacity: 0;
  }
}

.mainContainer {
  margin-top: $headerHeight;
  padding-top: 1rem;

  h1 {
    text-align: center;
    margin-bottom: 30px;
  }

  .linkText {
    color: $mainGreen;
    background-color: white;
    border: none;
    padding: 0 4px;
  }

  .submitButton {
    display: flex;
    justify-content: center;
  }

  .btn-listing {
    display: flex;
    height: 40px;
    align-items: center;

    img {
      height: 20px;
      margin-right: 5px;
    }

    p {
      align-self: flex-start;
    }
  }

  .card {
    margin-top: 20px;
  }

  .emptyErrorMessage {
    text-align: center;
    margin-top: $headerHeight + $searchBarHeight + 50px;
  }

  .card-body {
    h5 {
      margin-bottom: 0.5rem;
    }
    p {
      margin-bottom: 0.5rem;
    }

    .location {
      display: flex;
      align-items: center;

      p {
        margin-bottom: 0;
      }

      img {
        height: 15px;
      }
    }

    .btn {
      margin-top: 10px;
    }
  }

  .closeButton {
    display: flex;
    justify-content: flex-end;

    @mixin cross($size: 20px, $color: currentColor, $thickness: 1px) {
      margin: 0;
      padding: 0;
      border: 0;
      background: none;
      position: relative;
      width: $size;
      height: $size;

      &:before,
      &:after {
        content: "";
        position: absolute;
        top: ($size - $thickness) / 2;
        left: 0;
        right: 0;
        height: $thickness;
        background: $color;
        border-radius: $thickness;
      }

      &:before {
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
      }

      span {
        display: block;
      }
    }

    .btn-close {
      margin: 0;
      border: 0;
      padding: 0;
      background: #a2c11c;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: all 150ms;

      .icon-cross {
        @include cross(18px, #fff, 3px);
      }

      &:hover,
      &:focus {
        transform: rotateZ(90deg);
        background: $mainGreen;
      }
    }
  }

  .signupPage {
    max-width: 650px;
    .form-label {
      margin-top: 7px;
    }
    .btn {
      margin-bottom: 15px;
    }
  }

  .loginPage {
    max-width: 500px;

    .signUpLink {
      margin-top: 80px;
      display: flex;
      align-items: flex-start;
    }
  }

  .homePage {
    margin-bottom: $navBarHeight;

    .spinner-border {
      margin-left: auto;
      margin-right: auto;
      margin-top: 45vh;
      display: block;
      border-color: $mainGreen;
      border-right-color: transparent;
    }

    .addingItem {
      display: none;
    }

    .editProfileText {
      font-weight: 300;
    }

    .searchArea {
      width: 100%;
      background-color: #ffffff;
      position: fixed;
      top: $headerHeight;
      left: 50%;
      transform: translateX(-50%);
      padding: 20px 0;
      z-index: 10000;
      height: $searchBarHeight;

      .searchBar {
        display: flex;
        justify-content: space-between;
        background-color: #ffffff;
        border-radius: 20px;
        padding: 3px 0;
        width: 250px;
        margin: 0 auto;
        border-color: $mainGreen;
        border-width: 2.5px;
        border-style: solid;
        align-items: center;

        input {
          border: none;
          background-color: transparent;
          width: 150px;
          padding-left: 10px;
          &::placeholder {
            color: rgb(44, 93, 99, 0.5);
          }
          &:focus {
            outline: none;
            color: $dark;
          }
        }

        img {
          height: 23px;
          margin-right: 10px;
        }
      }
    }

    .availableItemListing {
      margin-top: $searchBarHeight;
    }
  }

  .addToListingPage {
    margin-top: -15px;
    margin-bottom: 25px;
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 20px;
    max-width: 650px;

    h1 {
      margin-bottom: 10px;
      margin-top: -10px;
    }

    ::placeholder {
      color: rgb(128, 128, 128, 0.4);
      opacity: 1;
    }
    .form-label {
      margin-top: 6px;
    }
    .form-text {
      color: #a2c11c;
    }

    .listingButton {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;

      .btn {
        display: flex;
        align-items: center;
      }

      .hide {
        display: none;
      }

      .spinner-border {
        border-color: $mainGreen;
        border-right-color: transparent;
        height: 1.2rem;
        width: 1.2rem;
        margin-left: 5px;
        border-width: 0.16rem;
      }
    }
  }

  .profilePage {
    margin: 0 20px;

    .row {
      margin: 0;
    }
    .profileLabel {
      padding-left: 0;
      font-weight: 200;
    }

    .editButton {
      background-color: transparent;
      border: none;
      color: #a2c11c;
      font-style: italic;
      padding-left: 12px;
      margin-bottom: 10px;
    }

    .saveButton {
      background-color: $mainGreen;
      color: white;
      display: block;
      border-color: transparent;
      box-shadow: 3px 3px $mainBlue;
      margin: 0 auto 10px auto;
    }

    .profileField {
      margin-bottom: 10px;
      padding: 0 0 10px 0;
      border-bottom: 1px solid $mainGreen;
    }

    .logout {
      margin-top: 10px;
      margin-bottom: 10px;
      display: flex;
      justify-content: center;
    }
  }

  .basketPage {
    max-width: 620px;

    .basketTimeLimit {
      margin-top: 1rem;
    }
    .emptyBasket {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: $mainGreen;
      // position: fixed;
      // top: $headerHeight;
      // left: 0;
      height: 50vh;
      // margin-top: -$navBarHeight;
      margin: 0 auto;
      // width: 100vw;

      img {
        width: 10vw;
        min-width: 60px;
        max-width: 85px;
        margin-bottom: 20px;
      }
    }

    .checkoutHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .btn {
        margin-top: 0;

        img {
          height: 20px;
        }
      }
    }
  }

  .listingPage {
    margin-bottom: $navBarHeight;
    max-width: 1000px;

    h6 {
      margin-bottom: 15px;
      color: $mainGreen;
    }
    .listingItemsAvailable {
      margin-bottom: 25px;

      > p {
        text-align: center;
      }
    }

    .collectedItems {
      > p {
        text-align: center;
      }
    }
  }

  .addToListing {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(255, 255, 255, 0.8);
    height: 60px;
    position: fixed;
    bottom: $navBarHeight - 13px;
    width: 100vw;
    z-index: 1000;
  }

  .collectionConfirmationPage {
    height: 100vh;
    width: 100vw;
    text-align: center;
    position: fixed;
    top: 0;
    // left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .collectionMessage {
      font-weight: 300;
    }
    h5 {
      margin-top: 10px;
    }

    .wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .checkmark__circle {
      stroke-dasharray: 166;
      stroke-dashoffset: 166;
      stroke-width: 2;
      stroke-miterlimit: 10;
      stroke: $mainGreen;
      fill: none;
      animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
    }
    .checkmark {
      width: 56px;
      height: 56px;
      border-radius: 50%;
      display: block;
      stroke-width: 2;
      stroke: #fff;
      stroke-miterlimit: 10;
      margin: 10% auto;
      box-shadow: inset 0px 0px 0px $mainGreen;
      animation: fill 0.4s ease-in-out 0.4s forwards,
        scale 0.3s ease-in-out 0.9s both;
    }
    .checkmark__check {
      transform-origin: 50% 50%;
      stroke-dasharray: 48;
      stroke-dashoffset: 48;
      animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
    }
    @keyframes stroke {
      100% {
        stroke-dashoffset: 0;
      }
    }
    @keyframes scale {
      0%,
      100% {
        transform: none;
      }
      50% {
        transform: scale3d(1.1, 1.1, 1);
      }
    }
    @keyframes fill {
      100% {
        box-shadow: inset 0px 0px 0px 30px $mainGreen;
      }
    }
  }
}

//////
////
//media-queries
////
/////

@media (min-width: 470px) {
  .mainContainer {
    .profilePage {
      padding: 1rem 0;
      margin: 0 auto;
      // margin-bottom: 1rem;
      max-width: 530px;
      background-color: #ffffff;

      .profileButton {
        max-width: 430px;
        margin: 0 auto;
      }

      .row {
        max-width: 430px;
        margin: 0 auto;
      }
    }

    .basketPage {
      padding: 1rem 2rem;
    }

    .listingPage {
      padding: 1rem 3rem;
    }
  }
}

@media (max-width: 700px) {
  .largeScreenNav {
    display: none;
  }
}

@media (min-width: 700px) {
  body {
    background-color: #f7f7f7;
  }
  nav {
    display: none;
  }

  h6 {
    font-size: 1.3rem;
  }

  .largeScreenNav {
    display: initial;
    button {
      margin-right: 1.5rem;
      color: #ffffff;
      background-color: transparent;
      border: none;
      font-size: 1.1rem;
    }
    .activePage {
      color: $mainGreen;
    }
  }

  .mainContainer {
    background-color: #f7f7f7;
    padding-bottom: 2rem;
    padding-top: 2rem;

    .profilePage {
      padding-top: 2rem;
    }

    .addToListingPage {
      margin-top: 10px;
      background-color: #ffffff;
      padding: 1rem 2rem;

      .listingFields {
        font-size: 1.2rem;
      }

      .listingButton {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;

        .btn {
          font-size: 1.3rem;
        }
      }
    }

    .listingPage {
      background-color: #ffffff;
      padding-top: 2rem;
      padding-bottom: $navBarHeight;
      margin-bottom: 0;
      margin-top: -2rem;
      height: 100vh;

      .wideListing {
        display: flex;

        h6 {
          text-align: center;
        }
      }

      .wideListing > * {
        flex: 1 1 0;
        padding-left: 15px;
        padding-right: 15px;
      }
    }
    .addToListing {
      bottom: 0px;
      height: 100px;
      width: 1000px;
      left: 50%;
      transform: translateX(-50%);

      .btn-listing {
        height: 40px;
        display: flex;
        align-items: center;

        img {
          height: 30px;
          margin-right: 8px;
        }

        p {
          align-self: center;
          font-size: 1.3rem;
          margin-bottom: 0;
        }
      }
    }
    .basketPage {
      background-color: #ffffff;
      padding: 2rem;
    }

    .homePage {
      margin-top: -2rem;
      .searchArea {
        background-color: #f7f7f7;
      }
    }
  }
}

@media (min-width: 1100px) {
  .largeScreenNav {
    margin-right: 3rem;
  }

  .mainContainer {
    .listingPage {
      margin-top: 0;
    }
  }
}

@media (min-width: 1300px) {
  .mainContainer {
    .homePage .availableItemListing {
      max-width: 1200px;
    }
  }
}
